import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RecaptchaModule } from 'ng-recaptcha-2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StopWatchComponent } from '@cogent/client/shared/components/visuals/stop-watch/stop-watch.component';
import { CustomerRegisterComponent } from '@cogent/client/shared/components/customer-portal/register/register.component';
import { NgxStarRatingModule } from '@cogent/client/shared/components/misc/ngx-star-rating/ngx-star-rating.module';
import { OrderMaintenanceServiceComponent } from '@cogent/client/shared/components/maintenance-service/order-maintenance-service/order-maintenance-service.component';
import { SingleAddressEntryComponent } from '@cogent/client/shared/components/misc/single-address-entry/single-address-entry.component';
import { AddressStreetViewComponent } from '@cogent/client/shared/components/misc/address-street-view/address-street-view.component';
import { JobItemSelectionComponent } from '@cogent/client/shared/components/service/job-item-selection/job-item-selection.component';
import { WorkOrderSurveyComponent } from '@cogent/client/apps/homeowner/service/work-order-survey/work-order-survey';
import { RequestServiceComponent } from '@cogent/client/shared/components/service/request-service/request-service';
import { HomeownersHomeComponent } from '@cogent/client/apps/homeowner/home/homeowners-home.component';
import { ChoosePropertyComponent } from '@cogent/client/apps/homeowner/service/choose-property/choose-property.component';
import { ViewCoverageDialogComponent } from '@cogent/client/shared/components/service/view-coverage/view-coverage.component';
import { MakePaymentComponent } from '@cogent/client/apps/homeowner/accounting/make-payment/make-payment.component';
import { ViewPolicyComponent } from '@cogent/client/apps/homeowner/general/view-policy/view-policy.component';
import { AutoRenewComponent } from '@cogent/client/apps/homeowner/sales/auto-renew/auto-renew.component';
import { SettingsComponent } from '@cogent/client/apps/homeowner/general/settings/settings.component';
import { StartupService } from './startup.service';
import { RenewComponent } from '@cogent/client/apps/homeowner/sales/renew/renew.component';
// import { PlanSelectionComponent } from './parts/plan-selection/plan-selection.component';
import { OrderComponent } from '@cogent/client/apps/homeowner/sales/order/order.component';
import { CancelWorkOrderComponent } from '@cogent/client/apps/homeowner/service/cancel-work-order/cancel-work-order.component';
import { environment } from '../environments/environment';
import { AddCoverageMenuComponent } from '@cogent/client/apps/homeowner/sales/add-coverage-menu/add-coverage-menu.component';
import { AttachPolicyComponent } from '@cogent/client/apps/homeowner/registration/attach-policy/attach-policy.component';
import { RescheduleAppointmentComponent } from '@cogent/client/apps/homeowner/service/reschedule-appointment/reschedule-appointment.component';
import { ChangePasswordComponent } from '@cogent/client/apps/homeowner/security/change-password/change-password.component';
import { ResetPasswordComponent } from '@cogent/client/apps/homeowner/security/reset-password/reset-password.component';
import { ViewOnMyWayComponent } from '@cogent/client/apps/homeowner/service/view-on-my-way/view-on-my-way.component';
import { OrderSummaryComponent } from '@cogent/client/apps/homeowner/sales/order-summary/order-summary.component';
import { ErrorComponent } from '@cogent/client/apps/homeowner/general/error/error.component';
import { NewOrderComponent } from '@cogent/client/apps/homeowner/sales/new-order/new-order.component';
import { ConnectChatComponent } from '@cogent/client/shared/components/website-parts/connect-chat/connect-chat.component';
import { PaperlessPromptComponent } from '@cogent/client/shared/components/sales/paperless-prompt/paperless-prompt.component';
import { UpdateCustomerCreditCardComponent } from '@cogent/client/apps/homeowner/accounting/update-customer-credit-card/update-customer-credit-card.component';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { SearchChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/search-chip-selection/search-chip-selection.module';
import { DateRangeDisplayAndSelectorComponent } from '@cogent/client/shared/components/misc/date-range-display-and-selector/date-range-display-and-selector.component';
import { PlanOfferingsModule } from '@cogent/client/shared/components/plans-and-coverage/plan-offerings1/plan-offerings.module';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { FrownyFaceComponent } from '@cogent/client/shared/components/misc/frowny-face/frowny-face.component';
import { CalendarDayViewerModule } from '@cogent/client/shared/components/data-visualization/calendar-day-view/calendar-day-viewer.module';
import { ChangeAutoRenewalComponent } from '@cogent/client/shared/components/sales/change-auto-renewal/change-auto-renewal/change-auto-renewal.component';
import { ClosingDocumentUploadModule } from '@cogent/client/shared/components/sales/closing-document-upload/closing-document-upload.module';
import { FunctionQuestionRendererModule } from '@cogent/client/shared/components/functions/function-question-renderer/function-question-renderer.module';
import { RouteWatcherComponent } from '@cogent/client/shared/components/website-parts/route-watcher/route-watcher.component';
import { WebSocketHandlerModule } from '@cogent/client/shared/components/website-parts/web-socket-handler/web-socket-handler.module';
import { WrenchCircleAnimatedModule } from '@cogent/client/shared/components/visuals/wrench-circle-animated/wrench-circle-animated.module';
import { UpsellCoverageComponent } from '@cogent/client/shared/components/sales/upsell-coverage/upsell-coverage/upsell-coverage.component';
import { AddOnRequestArgsViewerComponent } from '@cogent/client/shared/components/maintenance-service/add-on-request-args-viewer/add-on-request-args-viewer.component';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { CircleWidgetComponent } from '@cogent/client/shared/components/data-visualization/circle-widget/circle-widget.component';
import { BasicEntityEntryModule } from '@cogent/client/shared/components/entities/basic-entity-entry/basic-entity-entry.module';
import { AppointmentSelectionComponent } from '@cogent/client/shared/components/service/appointment-selection/appointment-selection.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { QuestionWizardComponentV2 } from '@cogent/client/shared/components/functions/question-wizard-v2/question-wizard-v2.component';
import { CircleWidgetSmallModule } from '@cogent/client/shared/components/data-visualization/circle-widget-small/circle-widget-small.module';
import { BasicEntitySummaryModule } from '@cogent/client/shared/components/entities/basic-entity-summary/basic-entity-summary.module';
import { CommonModule } from '@angular/common';
import { AddressEditorComponent } from '@cogent/client/shared/components/misc/address-editor/address-editor.component';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UploadPhotosComponent } from '@cogent/client/shared/components/service/upload-photos/upload-photos.component';
import { RealtorPortalLoginComponent } from '@cogent/client/apps/homeowner/sales/realtor-portal-login/realtor-portal-login.component';
import { DocumentUploadModalComponent } from '@cogent/client/apps/homeowner/general/document-upload-modal/document-upload-modal.component';
import { DocumentUploadRouteComponent } from '@cogent/client/apps/homeowner/general/document-upload-route/document-upload-route.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AcceptAlternateTimeComponent } from '@cogent/client/apps/homeowner/service/accept-alternate-time/accept-alternate-time.component';
import { ViewCoverageComponent } from '@cogent/client/apps/homeowner/service/view-coverage/view-coverage.component';
import { CreateFollowUpComponent } from '@cogent/client/apps/homeowner/service/create-follow-up/create-follow-up.component';
import { PartsTrackingComponent } from '@cogent/client/apps/homeowner/service/parts-tracking/parts-tracking.component';
import { ApproveCashOutComponent } from '@cogent/client/apps/homeowner/service/approve-cash-out/approve-cash-out.component';
import { NotificationsComponent } from '@cogent/client/apps/homeowner/general/notifications/notifications.component';
import { PrivacyComponent } from '@cogent/client/shared/components/website-parts/privacy/privacy.component';
import { MaintServiceBannerComponent } from '@cogent/client/shared/components/maintenance-service/maint-service-banner/maint-service-banner.component';
import { MaintenanceServicePropertyViewComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maintenance-service-property-view/maintenance-service-property-view.component';
import { MaintServicesContractLanguageComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-contract-language/maint-services-contract-language.component';
import { MaintServicesRateServiceComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-rate-service/maint-services-rate-service.component';
import { MaintServicesChangeFrequencyComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-change-frequency/maint-services-change-frequency.component';
import { AppsComponent } from '@cogent/client/apps/homeowner/general/apps/apps.component';
import { MaintServicesViewAppointmentComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-view-appointment/maint-services-view-appointment.component';
import { MaintServicesCancelComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-cancel/maint-services-cancel.component';
import { UpdateWarrantyPaymentMethodComponent } from '@cogent/client/apps/homeowner/accounting/update-warranty-payment-method/update-warranty-payment-method.component';
import { MaintServicesRequestAddOnComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-request-add-on/maint-services-request-add-on.component';
import { PaymentReportComponent } from '@cogent/client/apps/homeowner/accounting/payment-report/payment-report.component';
import { CustomerTestComponent } from '@cogent/client/apps/homeowner/general/customer-test/customer-test.component';
import { HomeownerWorkOrderDetailTwoComponent } from '@cogent/client/apps/homeowner/service/homeowner-work-order-detail-two/homeowner-work-order-detail-two.component';
import { EnterCreditCardFromWorkflowComponent } from '@cogent/client/apps/homeowner/general/enter-credit-card-from-workflow/enter-credit-card-from-workflow.component';
import { ApproveAddOnBidComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/approve-add-on-bid/approve-add-on-bid.component';
import { ApproveContactorProposedWorkOrderComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/approve-contactor-proposed-work-order/approve-contactor-proposed-work-order.component';
import { LoginComponent } from '@cogent/client/apps/homeowner/security/login/login.component';
import { EnterActionDirective } from '@cogent/client/shared/directives/enter-action.directive';
import { AutoAuthenticateComponent } from '@cogent/client/apps/homeowner/security/auto-authenticate/auto-authenticate.component';
import { MaintServicesLandingComponent } from '@cogent/client/apps/homeowner/scheduled-maintenance/maint-services-landing/maint-services-landing.component';
import { RenewNowDialogComponent } from '@cogent/client/shared/components/customer-portal/renew-now-dialog/renew-now-dialog.component';
import { DoughnutComponent } from '@cogent/client/shared/components/data-visualization/doughnut/doughnut.component';
import { ApiFailureComponent } from '@cogent/client/shared/components/api-failure/api-failure.component';
import { DeleteAccountComponent } from '@cogent/client/shared/components/delete-account/delete-account.component';
import { CustomerFooterComponent } from "@cogent/client/shared/components/customer-portal/customer-footer/customer-footer.component";
import { FillOutFormComponent } from '@cogent/client/shared/components/functions/fill-out-form/fill-out-form.component';
import { LandingPageComponent } from '@cogent/client/apps/homeowner/general/landing-page/landing-page.component';
import { CalendarComponent } from '@cogent/client/apps/homeowner/general/calendar/calendar.component';
import { PhoneLinkModule } from '@cogent/client/shared/components/misc/phone-link/phone-link.module';





export function startupServiceFactory(startupService: StartupService) {
    return () => startupService.load();
}

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        CommonModule,
        //DialogsModule,
        ApiFailureComponent,
        FormsModule,
        PrivacyComponent,
        BrowserAnimationsModule,
        AppRoutingModule,
        MaterialSharedModule,
        DragDropModule,
        NgxMaskDirective,
        CancelWorkOrderComponent,
        PaperlessPromptComponent,
        DocumentUploadModalComponent,
        DocumentUploadRouteComponent,
        AppsComponent,
        RenewComponent,
        RecaptchaModule,
        AutoAuthenticateComponent,
        MaintServicesChangeFrequencyComponent,
        ReactiveFormsModule,
        LoginComponent,
        MaintServicesViewAppointmentComponent,
        AcceptAlternateTimeComponent,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        NgxStarRatingModule,
        CircleWidgetSmallModule,
        ErrorComponent,
        MaintServicesLandingComponent,
        MaintServicesContractLanguageComponent,
        ApproveContactorProposedWorkOrderComponent,
        AttachPolicyComponent,
        NewOrderComponent,
        ViewCoverageComponent,
        LandingPageComponent,
        RealtorPortalLoginComponent,
        CircleWidgetComponent,
        CalendarComponent,
        FillOutFormComponent,
        EnterCreditCardFromWorkflowComponent,
        AddCoverageMenuComponent,
        UploadPhotosComponent,
        CreateFollowUpComponent,
        DisplayTotalModule,
        DatePickerWrapperComponent,
        AddressEditorComponent,
        MaintServicesCancelComponent,
        UpdateWarrantyPaymentMethodComponent,
        RequestServiceComponent,
        ApproveCashOutComponent,
        AppointmentSelectionComponent,
        MakePaymentComponent,
        MaintServicesRequestAddOnComponent,
        NotificationsComponent,
        ApproveAddOnBidComponent,
        PartsTrackingComponent,
        MaintServicesRateServiceComponent,
        HomeownerWorkOrderDetailTwoComponent,
        BasicEntitySummaryModule,
        PaymentReportComponent,
        CustomerTestComponent,
        ResetPasswordComponent,
        ChangePasswordComponent,
        UpdateCustomerCreditCardComponent,
        // QuestionWizardModule,
        QuestionWizardComponentV2,
        WorkOrderSurveyComponent,
        RescheduleAppointmentComponent,
        SettingsComponent,
        HomeownersHomeComponent,
        ConnectChatComponent,
        MaintServiceBannerComponent,
        PhoneLinkModule,
        BasicEntityEntryModule,
        ViewCoverageDialogComponent,
        WrenchCircleAnimatedModule,
        CheckCircleAnimatedComponent,
        ChangeAutoRenewalComponent,
        PaymentMethodEntryModule,
        MaintenanceServicePropertyViewComponent,
        // PlanSelectionComponent,
        ChoosePropertyComponent,
        EnterActionDirective,
        RenewNowDialogComponent,
        ViewOnMyWayComponent,
        ViewCoverageDialogComponent,
        OrderComponent,
        DeleteAccountComponent,
        UpsellCoverageComponent,
        ViewPolicyComponent,
        AddOnRequestArgsViewerComponent,
        ClosingDocumentUploadModule,
        FrownyFaceComponent,
        OrderSummaryComponent,
        RouteWatcherComponent,
        CalendarDayViewerModule,
        SingleAddressEntryComponent,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        UpkeepPipesModule, AddressStreetViewComponent,
        FrownyFaceComponent,
        RouteWatcherComponent,
        PlanOfferingsModule,
        CustomerRegisterComponent,
        FunctionQuestionRendererModule,
        DateRangeDisplayAndSelectorComponent,
        WebSocketHandlerModule, SearchChipSelectionModule,
        AutoRenewComponent,
        OrderMaintenanceServiceComponent,
        StopWatchComponent,
        JobItemSelectionComponent,
        DoughnutComponent, CustomerFooterComponent], providers: [
        provideNgxMask(),
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true
        },
        { provide: 'authConfig', useValue: environment.authConfig },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
