<section id="overview" class="home-static section-back-image soft-light-bg">
    <div id="background-image-container" [class.opacity-50]="scrollPosition > 50"
        [class.opacity-25]="scrollPosition > 100" [class.opacity-10]="scrollPosition > 150"
        [class.opacity-0]="scrollPosition > 200">

    </div>
    <div class="container mobile-padding" style="position: relative;">


        <h1 *ngIf="customerFirstName" style="text-align: left;margin-top: 40px">
            Hello <br><span style="font-size: 72px">{{customerFirstName}}</span>
        </h1>

        <div class="home-static-text text-center">

            <div *ngIf="unreadNotificationCount" topmargin40 class="message-notification soft-light fade-in-up ">
                <mat-icon>notifications</mat-icon>
                <p topmargin30>
                    You have {{unreadNotificationCount}} unread message(s)</p>
                <a mat-button routerLink="/notifications" mat-raised-button>Read Now</a>
                <button mat-button (click)="unreadNotificationCount = 0">Dismiss</button>
            </div>
            <div class="mobile-margin" bottommargin30>
                <a mat-button mat-raised-button mobile-block large color="primary" [disabled]="!canSubmitServiceRequest"
                    style="margin-top: 30px" class="page-scroll arrow-btn" [routerLink]="requestServiceRoute">Request
                    Service</a>


            </div>
        </div>
        <div class="blur-elements" style="margin-top: 100px">
            <div class="savings-upcoming-parent">
                <div id="savings-summary" class="row about-single soft-light" style="flex-direction: row;">
                    <div flex style="text-align: center;padding-top: 30px;transform: scale(.75);">
                        <app-doughnut [label]="expensesLabel" [metrics]="mappedExpenses"></app-doughnut>
                    </div>
                    <div *ngIf="noExpenses" class="show-xs">
                        * Your potential estimated savings based on average customer savings.
                    </div>
                    <div class="hide-xs" style="padding-top: 150px">
                        <!-- <img style="height: 200px;float:left" src="assets/images/saving-money.png"> -->
                        <!-- <h2 topmargin30 class="fancy-title" *ngIf="!noExpenses">Congratulations</h2> -->
                        <p *ngIf="!noExpenses">* Based on estimated retail prices for work completed</p>
                        <p *ngIf="noExpenses">
                            * Your potential estimated savings based on average customer savings.
                        </p>
                    </div>
                </div>

                <div class="soft-light about-single" style="min-height: 250px;">
                    <div style="display: flex;height: 100%;flex-direction:column">
                        <div style="flex: 1">
                            <h2>Upcoming Payments</h2>
                            <div style="margin-top: 10px;"></div>
                            <ng-container *ngFor="let invoice of upcomingInvoices; let i = index" style="overflow-y: scroll">
                                <div aflex style="justify-content: center;" class="upcoming-invoice" *ngIf="i<2">
                                    <div nogrow>
                                        <span><strong>{{invoice.description}}</strong><br>${{invoice.amountDue}} - {{invoice.formattedDueDate}}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div style="flex:none" aflex>
                            <div></div>
                            <a mat-button nogrow routerLink="/make-payment">
                                <div>
                                See More <mat-icon>chevron_right</mat-icon></div>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="soft-light about-single" style="min-height: 250px;">

                    <div style="display: flex;height: 100%;flex-direction:column">
                        <div style="flex: 1">


                            <h2>Upcoming Appointments</h2>
                            <div style="margin-top: 10px;"></div>
                            <ng-container *ngFor="let job of upcommingJobs">
                                <a mat-button class="upcomming-job-link" *ngIf="!job.type"
                                    routerLink="/work-order/{{job.id}}">
                                    <div aflex class="upcomming-job">
                                        <div nogrow>

                                            <h2>{{job.scheduledDate | date: 'd'}}</h2>
                                            <div>{{job.scheduledDate | date: 'MMM'}}</div>
                                        </div>
                                        <div style="margin-left: 0;">
                                            <div>{{job.number}} - {{job.itemName}}</div>
                                            <div>{{job.propertyAddress}}</div>
                                            <div>{{job.scheduledStartWindow}} - {{job.scheduledEndWindow}}</div>
                                        </div>
                                    </div>
                                </a>
                                <a mat-button class="upcomming-job-link" *ngIf="job.type === 'Maintenance'"
                                    routerLink="/maintenance-service-property/{{job.id}}"
                                    [queryParams]="{appointmentId: job.appointmentId}">
                                    <div aflex class="upcomming-job">
                                        <div nogrow>

                                            <h2>{{job.scheduledDate | date: 'd'}}</h2>
                                            <div>{{job.scheduledDate | date: 'MMM'}}</div>
                                        </div>
                                        <div style="margin-left: 0;">
                                            <div>{{job.itemName}}</div>
                                            <div>{{job.propertyAddress}}</div>
                                        </div>
                                    </div>
                                </a>
                            </ng-container>

                        </div>
                        <div style="flex:none" aflex>
                            <div></div>
                            <a mat-button nogrow routerLink="/calendar">
                                <div>
                                See More <mat-icon>chevron_right</mat-icon></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="three-column-grid" topmargin20>
                <div class="about-single soft-light" style="display: flex;flex-direction: column;">
                    <div style="flex: 1;">
                        <h4 *ngIf="myFilteredPolicies">{{myFilteredPolicies.length}}</h4>
                        <p class="data-label">Covered Properties</p>
                    </div>
                    <div class="flex" style="flex:none">
                        <div></div>
                        <div class="no-grow">
                            <a mat-button class="more-button" (click)="scrollToFragment('#properties')"><div>See
                                More
                                <mat-icon>chevron_right</mat-icon></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="about-single soft-light" style="display: flex;flex-direction: column;">

                    <div style="flex:1;">
                        <h4>{{openWorkOrderCount}}</h4>
                        <p class="data-label">Open service requests.</p>
                    </div>
                    <div style="flex:none">
                        <div class="flex">
                            <div></div>
                            <div class="no-grow">
                                <a mat-button class="more-button" (click)="scrollToFragment('#service-requests')"><div>See
                                    More 
                                    <mat-icon>chevron_right</mat-icon></div></a>
                            </div>
                        </div>
                    </div>



                </div>
                <div class="about-single soft-light" style="display: flex;flex-direction: column;">

                    <div style="flex:1;">
                        <h4>{{balanceDue | currency : 'USD': 'symbol-narrow' : '1.0-0'}}</h4>
                        <p class="data-label">Unpaid Invoices</p>
                    </div>
                    <div style="flex:none">
                        <div class="flex">
                            <div>

                            </div>
                            <div class="no-grow">
                                <a mat-button class="more-button" (click)="scrollToFragment('#payments')">
                                    <div>
                                    See
                                    More
                                    
                                <mat-icon>chevron_right</mat-icon></div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="three-column-grid" *ngIf="maintenanceAvailable  && maintainedProperties && maintainedProperties.length" topmargin20>
                <div class="about-single soft-light" style="display: flex;flex-direction: column;">
                    <div style="flex:1;">
                        <h4>{{maintainedProperties.length}}</h4>
                        <p class="data-label">Maintenance Properties</p>
                    </div>
                    <div style="flex:none">
                        <div class="flex">
                            <div>

                            </div>
                            <div class="no-grow">
                                <a mat-button class="more-button" (click)="scrollToFragment('#maintenance-services')">
                                    <div>
                                    See
                                    More
                                    
                                <mat-icon>chevron_right</mat-icon></div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <a mat-button (click)="scrollToFragment('#properties')" [class.hide-btn]="scrollPosition > 150" class="more-btn">
        More
        <div>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
    </a>
</section>
<section id="properties" class="about-us section-padding">
    <div class="container text-center">
        <div class="row" style="flex-direction: column">
            <div class="section-title">
                <h1>My Covered Properties</h1>

            </div>
            <div class="three-column-grid">
                <div class="property-container solid-shadow small" *ngFor="let policy of myFilteredPolicies">
                    <div style="display: flex;flex-direction: column;height: 100%;">
                        <div style="flex: 1">
                            <span class="badge">{{policy.status}}</span>
                            <img src="{{baseUrl}}policy/{{policy.id}}/StreetView?width=150&height=150" />
                            <h2 topmargin30>{{policy.propertyAddress.address1}}</h2>
                            <p>{{policy.planName}}</p>
                        </div>
                        <div style="flex:none" flex>
                            <div></div>
                            <div nogrow>
                                <a mat-button routerLink="/view-property/{{policy.id}}"><div>Manage
                                    <mat-icon>chevron_right</mat-icon></div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 30px" *ngIf="loggedInUser && loggedInUser.type === 'Customer'">
                <p>Do you need to add coverage to a new property?</p>
                <a mat-button mat-raised-button color="primary" data-wow-delay="0.5s" data-wow-duration="1s"
                    class="page-scroll wow fadeInUp" routerLink="/add-coverage-menu"
                    style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeInUp;">Add
                    Covered Property</a>
            </div>
            <div style="margin-top: 30px" *ngIf="loggedInUser && loggedInUser.type === 'Agent'">
                <p>Do you need to add coverage to a new property?</p>
                <a mat-button mat-raised-button color="primary" data-wow-delay="0.5s" data-wow-duration="1s"
                    class="page-scroll wow fadeInUp" routerLink="/new-order"
                    style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeInUp;">Add
                    Covered Property</a>
            </div>
        </div>
    </div>
</section>
<section id="service-requests" class="section-padding home-static soft-light-bg">
    <div class="container">
        <div class="row text-center" style="flex-direction: column;margin-bottom: 30px">
            <div class="section-title">
                <h1>Service Requests</h1>
            </div>
            <div>
                <a data-wow-delay="0.5s" mat-button mat-raised-button large style="margin-bottom: 30px" color="primary"
                    [routerLink]="requestServiceRoute" data-wow-duration="1s" class="page-scroll wow fadeInUp"
                    href="#about" [disabled]="!canSubmitServiceRequest"
                    style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeInUp;">Start
                    A
                    Service Request</a>
            </div>
        </div>

        <a class="flex service-request-item" *ngFor="let workOrder of workOrders"
            routerLink="/work-order/{{workOrder.id}}">
            <div class="no-grow" style="width: 100px;">

                <img *ngFor="let line of workOrder.lines" [style.margin-left]="getThumbnailMargin(line, workOrder)"
                    src="{{getThumbnailUrl(line)}}" class="item-thumbnail" />
            </div>
            <div>
                <div flex>
                    <div>
                        <h3>{{workOrder.number}} - {{workOrder.itemName}} </h3>
                        <p>Opened: {{workOrder.createdDate | date:'shortDate'}} - {{workOrder.propertyAddress}}
                        </p>
                    </div>
                    <div nogrow>
                        <span class="badge">{{workOrder.status}}</span>
                    </div>
                </div>
            </div>
        </a>
        <button mat-button block *ngIf="showMoreWorkOrdersButton" [disabled]="loadingMoreWorkOrders" style="text-align: center;
    border-radius: 0 0 3px 3px;" (click)="loadMoreWorkOrders()"><div>
            <i class="material-icons" style="transform: rotate(90deg);" *ngIf="!loadingMoreWorkOrders">chevron_right</i>
            <mat-spinner *ngIf="loadingMoreWorkOrders" class="twenty"></mat-spinner> See More</div>
        </button>

    </div>
</section>

<section *ngIf="maintenanceAvailable" id="maintenance-services" class="section-padding">
    <div class="container text-center">
        <div class="section-title">
            <h1>Maintenance Services</h1>
        </div>
        <div class="three-column-grid">
            <div class="property-container col-md-4 col-sm-12 solid-shadow small" *ngFor="let policy of maintainedProperties">
                <div style="display: flex;flex-direction: column;height: 100%;">
                    <div style="flex: 1">
                        <span class="badge">{{policy.status}}</span>
                        <img src="{{baseUrl}}address/{{policy.propertyId}}/StreetView?width=150&height=150" />
                        <h2 topmargin30>{{policy.propertyAddress1}}</h2>
                        <p>{{policy.orderedServices}}</p>
                    </div>
                    <div style="flex:none" flex>
                        <div></div>
                        <div nogrow>
                            <a mat-button routerLink="/maintenance-service-property/{{policy.id}}">
                                <div>
                                Manage
                                <mat-icon>chevron_right</mat-icon></div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div>
            <a mat-button mat-raised-button color="primary" routerLink="/maintenance-service/portal-order">
                Order Services
            </a>
        </div>
    </div>
</section>

<section id="payments" 
    class="section-padding about-us " style="min-height: 600px">
    <div class="container">
        <div class="section-title">
            <h1>Invoices &amp; Payments</h1>

        </div>
        <div class="text-center" *ngIf="noUnpaidInvoices">
            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/paid-invoice-orange-blob.svg" style="height: 150px">

            <h3>Hooray!</h3>
            <p>You have no unpaid invoices.</p>
        </div>

        <div class="mobile-margin" *ngIf="!noUnpaidInvoices">
            <div style="text-align: left;">
                <h1 class="big-data">{{totalDue | currency}}</h1>
                <span class="data-label">Total Due</span>
            </div>
            <a mat-button mat-raised-button routerLink="/make-payment" color="primary">
                <div>
                Make Payment
                <mat-icon>chevron_right</mat-icon>
            </div>
            </a>
            <div style="margin-left: 20px;margin-right: 20px;">

                <a mat-button block class="flex service-request-item" style="text-align: left !important;display: block" *ngFor="let invoice of unpaidInvoices"
                    routerLink="/make-payment">

                    <div>
                        <h3>{{invoice.amountDue | currency}} - {{invoice.policyAddress1}}</h3>
                        <p>Opened: {{invoice.createdDate | date:'shortDate'}} - {{invoice.description}}</p>
                    </div>
                </a>
            </div>

        </div>

        <div topmargin30>
            <a mat-button mat-raised-button routerLink="/payment-report">
                View Past Payments
            </a>
        </div>
    </div>
</section>

<div *ngIf="noPoliciesDialog" class="fadeInUpDialogSmall" id="no-policies-dialog">

    <h2 class="sub-heading" bottommargin20>No Subscriptions</h2>
    <p class="info" style="line-height: 1.5">
        It looks like you don't have any subscriptions setup. No worries, we can get you setup today.
    </p>

    <a style="text-align: center" mat-button mat-raised-button color="primary" topmargin30 bottommargin20 large block
        routerLink="/order">
        Setup a new Property
    </a>

    <a style="text-align: center" mat-button mat-raised-button block routerLink="/attach-property" bottommargin20>Attach
        an Existing Subscription</a>

    <button mat-button block (click)="noPoliciesDialog = false">Not Now. No Thanks</button>
</div>
